import { Suspense } from "react"
import dynamic from "next/dynamic"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import LayoutAdmin from "@/layouts/Admin"
import PermissionGuard from "@/guards/PermissionGuard"
import AuthGuard from "@/guards/AuthGuard"
import LayoutCoreProvider from "@/layouts/CoreProvider"

import type { NextPageWithLayout } from "@/pages/_app"
import type { GetStaticProps } from "next"

const ViewNotFound404 = dynamic(() => import("@/views/NotFound404"), {
  suspense: true,
  ssr: false,
})

const NotFound404: NextPageWithLayout = () => {
  return (
    <Suspense fallback="...">
      <ViewNotFound404 />
    </Suspense>
  )
}

NotFound404.getLayout = (page, _, { translation }) => {
  const { t } = translation

  return (
    <LayoutCoreProvider
      headParams={{
        title: t("Not found")!,
      }}>
      <AuthGuard>{page}</AuthGuard>
    </LayoutCoreProvider>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || "")),
    },
  }
}

export default NotFound404
